<template>
  <div class="home-content">
    <!--    banner-->
    <div class="banner-box">
      <BannerSwipe/>
    </div>
    <!--    功德展示-->
    <div class="bless-title">
      <div class="bless-title-box">
        <img class="home_kuang_img" :src="require('@/assets/home_kuang.png')" alt=""/>
        <div class="gdzs">{{gdzs}}</div>
      </div>
    </div>

    <div class="merits-box">
      <div class="scroll-container">
        <div v-if="orderListData">
          <vue-seamless-scroll :data="orderListData" :class-option="optionHover" class="seamless-warp">
            <template v-for="(order,index) in orderListData">
              <div :key="index">
                <TempleOrderList :order="order"/>
              </div>
            </template>
          </vue-seamless-scroll>
        </div>
        <van-empty v-else description="数据加载中..." class="empty"/>
      </div>
    </div>
<!--    确认按钮-->
    <div class="diandeng-btn" @click="gotoDeviceListFn">
      <van-button class="btn"  type="danger" round>点灯祈福</van-button>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件 例如：组件、工具js、第三方插件js,json文件、图片文件等
// eg: import 《组件名称》 from '《文件路径》'
import BannerSwipe from '@/components/Banner/BannerSwipe.vue'
import TempleOrderList from '@/components/Home/TempleOrderList.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { getOrderListByTempleId } from '@/api/modules/order'
export default {
  name: 'HomeIndex',
  // import 引入的组件需要注入到对象中才能使用
  components: {
    TempleOrderList,
    BannerSwipe
  },
  props: {},
  // 这里用于存放数据
  data () {
    return {
      gdzs: '功德展示',
    }
  },
//  计算属性 类似于data概念
  computed: {
    ...mapState('order',['orderListData']),
    ...mapGetters({
      templeId: 'temple/getTempleId',
      templeName: 'temple/getTempleName'
    }),
    optionHover () {
      return {
        step: 0.5,
        hoverStop: false,
        openTouch: true,
        // limitMoveNum: this.orderListData.length + 1,
      }
    },
  },
//  监控data 中数据变化
  watch: {

  },
//  方法集合
  methods: {
    ...mapMutations('order', ['setOrderListData']),
    async initOrderListData () {
      if(this.templeId){
        const { data: { data } } = await getOrderListByTempleId(this.templeId)
        this.setOrderListData(data)
      }
    },
    gotoDeviceListFn(){
      this.$router.push('/devicelist')
    }
  },
//  生命周期 -创建完成（可以访问当前this 实例）
  created () {

    // this.initOrderListData()

  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted () {
    document.title = this.templeName
    this.initOrderListData()
  },
//  生命周期 - 创建之前
  beforeCreate () {
  },
//  生命周期 - 挂载之前
  beforeMount () {

  },
//  生命周期 - 更新之前
  beforeUpdate () {
  },
//  生命周期 - 更新之后
  updated () {
  },
//  生命周期 - 销毁之前
  beforeDestroy () {
  },
//  生命周期 - 销毁完成
  destroyed () {
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated () {
  }

}
</script>

<style scoped lang="less">
.home-content {
  .banner-box{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: #ffffff;
  }


  //功德展示*************************************************
  .bless-title {
    position: fixed;
    top: 174px;
    left: 0;
    right: 0;
    z-index: 1;
    background: #FFFFFF;
    .bless-title-box {
      margin: 10px 0;
      position: relative;
      line-height: 35px;
      text-align: center;
      display: flex;
      justify-content: center;
      height: 35px;
      .home_kuang_img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      img {
        width: 50%;
      }
      .gdzs {
        width: 80vw;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: rgba(153, 114, 58, 1);
        font-size: 16px;
      }
    }
  }
}

.diandeng-btn {
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 60px;
  width: 100vw;
  height: 80px;
  background-color: #fff;
  .btn {
    width: 330px;
    height: 46px;
    background: #A92B2A;
    border-radius: 23px;
  }
}

.merits-box {
  margin-top: 240px;
  position: absolute;
  height: calc(100vh - 30px);
  overflow-y: scroll;
  padding-bottom: 10px;
  -webkit-overflow-scrolling: touch;
  ///* 解决ios滑动不流畅问题 */
  .scroll-container{
    .empty{
      width: 100vw;
    }
  }
  .seamless-warp {
    height: 100vh;
    overflow: hidden;
  }
}

//************************************************************


</style>
